<template>
  <b-row class="mt-2 ml-3" v-if="formsLength > 1">
    <b-col cols="8">
      <label for="group_name">Nome do grupo</label>
      <b-form-input
        id="group_name"
        v-model="group_name"
        :readonly="!groupFiles"
        debounce="500"
      />
      <div v-if="!isValidated && !group_name" class="custom-invalid-feedback">
        Campo obrigatório
      </div>
    </b-col>
    <b-col cols="3" class="mt-5 mb-2 ml-auto">
      <label class="checkbox">
        <input type="checkbox" v-model="groupFiles" />
        <span class="checkmark">
          <Check />
        </span>
        Agrupar arquivos
      </label>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'GroupFiles',
  props: {
    formsLength: {
      type: Number,
      default: 0
    },
    isValidated: Boolean
  },
  components: {
    Check: () => import('@/assets/icons/check.svg')
  },
  data() {
    return {
      groupFiles: false,
      group_name: ''
    }
  },
  watch: {
    groupFiles(value) {
      this.group_name = value ? this.group_name : ''
      this.$emit('changeGroup', value)
    },
    group_name(value) {
      this.$emit('changeGroupName', value)
    }
  }
}
</script>
